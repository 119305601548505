import axios from "axios";
import swal from "sweetalert";
import { postErrorLog } from "./errorLog.service";

export const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_API_URL || "https://api-dev.brofesional.id",
});

axiosInstance.interceptors.request.use((config) => {
	let token = localStorage.getItem("TOKEN");

	if (window.location.pathname.includes("/report/")) {
		token = localStorage.getItem("PARENT_TOKEN");
	}

	if (token) {
		config.headers["Authorization"] = "Bearer " + token;
	}
	return config;
});

axiosInstance.interceptors.response.use(
	(response) => {
		return response;
	},
	async function (error) {
		if (
			error.response?.status === 401 &&
			window.location.pathname !== "/login"
		) {
			swal({
				title: "Unauthorized",
				text: "Sesi berakhir, silahkan login kembali",
				icon: "error",
			}).then(() => {
				localStorage.removeItem("TOKEN");
				localStorage.removeItem("USER");
				window.location.href = "/login";
				postErrorLog(error);
			});
		} else if (error.response?.status === 404) {
			swal({
				title: "Not Found",
				text: error.response?.data?.message ?? "Halaman tidak ditemukan",
				icon: "error",
			}).then(() => {
				postErrorLog(error);
			});
		} else if (error.response?.status === 500) {
			swal({
				title: "Internal Server Error",
				text: "Terjadi kesalahan pada server",
				icon: "error",
			}).then(() => {
				postErrorLog(error);
			});
		} else if (error.response?.status === 403) {
			swal({
				title: "Forbidden",
				text: "Anda tidak memiliki akses ke halaman ini",
				icon: "error",
			}).then(() => {
				postErrorLog(error);
			});
		} else if (error.response?.status === 400) {
			swal({
				title: "Bad Request",
				text: error.response?.data?.message ?? "Request tidak valid",
				icon: "error",
			});
		} else if (error.response?.status === 405) {
			swal({
				title: "Method Not Allowed",
				text: "Method tidak diizinkan",
				icon: "error",
			}).then(() => {
				postErrorLog(error);
			});
		} else if (error.response?.status === 409) {
			swal({
				title: "Conflict",
				text: "Data sudah ada",
				icon: "error",
			}).then(() => {
				postErrorLog(error);
			});
		} else if (error.response?.status === 422) {
			swal({
				title: "Oops!",
				text: error.response.data.message,
				icon: "error",
			});
		} else {
			if (window.location.pathname !== "/login") {
				swal({
					title: "Error",
					text: error.response?.data?.message ?? "Terjadi kesalahan",
					icon: "error",
				}).then(() => {
					postErrorLog(error);
				});
			}
		}
		return Promise.reject(error);
	}
);
